export type AuthFunction = () => PromiseLike<string | null | undefined | false>;

export const toUnordered = <T extends string>(
  prefix: string,
  includes: T[],
) => {
  return Object.fromEntries(
    includes.map((include) => [`${prefix}=${include}`, true]),
  );
};
